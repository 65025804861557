export const breadcrumbList = [
  {
    name: '营销管理',
    path: ''
  },
  {
    name: '营销列表',
    path: ''
  }
]
export const breadcrumbListBind = [
  {
    name: '营销管理',
    path: ''
  },
  {
    name: '服务绑定',
    path: '/main/coupon/serveBind'
  },
  {
    name: '添加绑定',
    path: ''
  }
]
export const breadcrumbInvite = [
  {
    name: '营销管理',
    path: ''
  },
  {
    name: '营销列表',
    path: '/main/marketing/list'
  },
  {
    name: '邀请有礼',
    path: ''
  }
]
export const breadcrumbFamily = [
  {
    name: '营销管理',
    path: ''
  },
  {
    name: '营销列表',
    path: '/main/marketing/list'
  },
  {
    name: '亲友账户',
    path: ''
  }
]
export const rule = {
  shopIds: [{ required: true, message: '请选择门店', trigger: 'blur' }]
}

